<template>
  <div class="admin-suitInfo">
    <div class="title">
      套装优惠详情
      <span style="margin-right: 20px; font-size: 12px">
        <span v-if="infoData.status_txt == '接下来的活动'" class="tag"
          >接下来的活动</span
        >
        <span v-if="infoData.status_txt == '进行中的活动'" class="tag1"
          >进行中的活动</span
        >
        <span v-if="infoData.status_txt == '已过期'" class="tag2">已过期</span>
      </span>
    </div>
    <div class="cell1">
      <div class="titles">基本信息</div>
      <div class="info">
        <div>
          套装类型：
          <span style="color: #000">{{ infoData.suit_type_txt }}</span>
        </div>
        <div>
          套装名称：
          <span style="color: #000">{{ infoData.suit_name }}</span>
        </div>
        <div>
          套装周期：
          <span style="color: #000"
            >{{ infoData.start_time | formatTime }}-{{
              infoData.end_time | formatTime
            }}</span
          >
        </div>
      </div>
      <div class="info" style="margin-top: -10px">
        <div>
          购买限制：
          <span style="color: #000"
            >买家最多可以购买此套装优惠{{ infoData.buy_limit }}次</span
          >
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles" style="margin-bottom: 10px">套装优惠订单总览</div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="套装优惠订单总览" name="first"></el-tab-pane> -->
      <!-- <el-tab-pane label="套装数据详情" name="second"></el-tab-pane> -->
      <div style="font-size: 12px; color: #b0b0b0; margin-bottom: 10px">
        共{{ listData.total }}个订单
      </div>
      <div class="list">
        <div class="list-head">
          <div style="width: 45%">商品详情</div>
          <div style="width: 15%">单价</div>
          <div style="width: 15%">数量</div>
          <div style="width: 15%">小计价格</div>
          <div style="width: 10%">订单状态</div>
        </div>
        <div
          class="list-content"
          v-if="listData.list && listData.list.length != 0"
        >
          <div v-for="item in listData.list" :key="item.id">
            <div class="one">
              <div style="width: 45%; display: flex">
                <div>
                  <el-image
                    style="width: 50px; height: 50px; margin-right: 10px"
                    :src="item.image"
                    fit="fit"
                  ></el-image>
                </div>
                <div>
                  <div
                    class="row-hidden"
                    style="width: 400px; height: 30px; font-size: 12px"
                  >
                    {{ item.title }}
                  </div>
                  <div style="font-size: 12px; color: #b0b0b0; margin-top: 4px">
                    全球商品货号：{{ item.goods_sn ? item.goods_sn : "--" }}
                  </div>
                </div>
              </div>
              <div style="width: 15%" v-if="item.discount_price != 0">
                RM{{ item.discount_price }}
                <span
                  style="
                    font-size: 12px;
                    text-decoration: line-through;
                    color: #b0b0b0;
                  "
                  >RM{{ item.price }}</span
                >
              </div>
              <div style="width: 15%" v-else>RM{{ item.price }}</div>
              <div style="width: 15%">{{ item.number }}</div>
              <div style="width: 15%">
                RM{{
                  item.discount_price == 0
                    ? (
                        parseFloat(item.price) * parseFloat(item.number)
                      ).toFixed(2)
                    : (
                        parseFloat(item.discount_price) *
                        parseFloat(item.number)
                      ).toFixed(2)
                }}
              </div>
              <div style="width: 10%">{{ status[item.state] }}</div>
            </div>
          </div>
        </div>
        <div
          v-else
          style="
            text-align: center;
            color: #b0b0b0;
            line-height: 100px;
            font-size: 14px;
          "
        >
          暂无
        </div>
      </div>
      <div style="display: flex; justify-content: right; padding-top: 20px">
        <el-pagination
          :page-size="page_size"
          :current-page="page"
          background
          layout="prev, pager, next"
          :total="listData.total"
          @current-change="pageChange"
          small
        >
        </el-pagination>
      </div>
    </div>
    <!-- <div style="display: flex; justify-content: right; margin-top: 30px">
      <el-button @click="toBack" size="small" style="min-width: 120px" plain
        >返回列表页</el-button
      >
    </div> -->
  </div>
</template>
<script>
import { suitDetails, suitOrderList } from "@/api/admin.js";
export default {
  data() {
    return {
      select: "1",
      infoData: "",
      listData: "",
      status: [
        "全部",
        "待支付",
        "待出货",
        "待发货",
        "运输中",
        "已完成",
        "已取消",
        "退货/退款",
      ],
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      suitDetails({
        id: sessionStorage.ADMIN_ACTIVITYID,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
        }
      });
    },
    getList() {
      suitOrderList({
        suit_id: sessionStorage.ADMIN_ACTIVITYID,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
  },
};
</script>
<style lang="less" >
.admin-suitInfo {
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 18px;
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      font-size: 12px;
      color: #929495;
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-tabs__nav {
      height: 50px;
    }
    .el-tabs__active-bar {
      height: 2px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
    .list {
      border: 1px solid #eee;
      .list-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        background: #f6f6f6;
        border-radius: 4px;
        padding: 10px 20px;
      }
      .list-content {
        font-size: 12px;
        .one {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          background: #fcfcfc;
        }
      }
    }
  }
}
</style>